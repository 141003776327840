<style>
    .bg-img-politicas{
        width: 100%;
        background-image: url("/img/tiempo.png");
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 460px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .pliticas_content_container{
        padding-top: 40px;
    }
    .list_links_container{
        position: relative;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
    color: #888;
    margin-bottom: 100px;
    
    }
    .list_links{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0;

    }
    .list_links_items{
        text-decoration: none;
        cursor: pointer;
        padding-bottom: 20px;
        margin-bottom: 20px;
        color: #667485;
        font-size: 1rem;
        margin-right: 20px;
        outline: none;
    }
    a{
        text-decoration: none;
    }
    a:hover{
        text-decoration: none;
        color: #007bff;
    }
   
    .active_link{
        border-bottom: 2px solid #007bff !important;
        
    }
</style>

<template>
    <div class="politicas_main_container">
         <div class="bg-img-politicas" >
                  <p  class="title text-white div-plan" data-aos="fade-up">Politicas</p>
     
                </div>


          <div class="pliticas_content_container">
            <div class="list_links_container">
                <nav class="list_links">
                    <a name="terminos" @click="changeLink" v-bind:class="[ `list_links_items ${current_page === 'terminos' ? 'active_link' : ''}`]" href="#">{{ terminos_condiciones }}</a>

                    <a name="privacidad"  @click="changeLink" v-bind:class="[`list_links_items ${current_page === 'privacidad' ? 'active_link' : ''}`]" href="#">{{politica_privacidad}}</a>

                    <a name="cookies"  @click="changeLink" v-bind:class="[`list_links_items ${current_page === 'cookies' ? 'active_link' : ''}`]" href="#">{{ poltica_cookies }}</a>
                    <!-- <a class="list_links_items">{{ aviso_legal }}</a> -->
                </nav>
            </div>
            
             <TerminosServicios v-if="current_page==='terminos'"/> 
             <politicasPrivacidad v-if="current_page === 'privacidad'"/> 
            <Cookies v-if="current_page === 'cookies'"/>
          </div>      
    </div>
</template>
<script>
import TerminosServicios from '../components/terminosServicios.vue';
import politicasPrivacidad from '../components/politicasPrivacidad.vue';
import Cookies from '../components/cookies.vue';

export default {
    data() {
        return {
            titulo: "Politicas",
            terminos_condiciones: "Terminos y condiciones",
            politica_privacidad: "Politica de privacidad",
            poltica_cookies: "Política de Cookies",
            aviso_legal: "Aviso legal",
            current_page:'terminos'
        };
    },
    mounted(){
        const ask = window.location.hash.split('=')[1]; 
       
        this.current_page = ask;
        console.log(this.current_page)
    },
    methods:{
        changeLink(e){
            e.preventDefault()

            let nombre = e.target['name']
            this.current_page = nombre
        }
    },
    components: {  Cookies, politicasPrivacidad, TerminosServicios }
}
</script>