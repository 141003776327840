import Vue from 'vue'
import App from './App.vue'
// import App from './components/Home1'
import router from './router'
import store from './store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import loading from 'vuejs-loading-screen'

Vue.use(loading, {
  bg: '#DFDFDF', // Fondo para el spinner
  slot: `
    
    <div class="flex justify-center items-center w-full h-full">
      <span class="loader"></span>
    </div>
  `,
})

// Import Bootstrap an BootstrapVue CSS files (order is important)
import './assets/css/styles.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
