<template>
    <div class="col-sm-4">
        <div class="row">
            <div class="col-12 mt-2">
                <img :src="item.img" alt="Avatar" class="w" style="filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 2));" @mouseover="hover = true" @mouseleave="hover = false" @touchstart="hover = true" @touchend="hover = false">
            </div>
        </div>
        <div class="container pt-3 mt-2">
            <h4 :class="['animated-texts', { 'hovered': hover }]" id="titles">{{item.titulo}}</h4>
        </div>
    </div>
</template>

<script>
export default {
    props: ["data"],
    data: () => ({
        className: "data",
        user: null,
        item: { tipo: '' },
        hover: false,
    }),
    mounted() {
        this.$store.watch(
            (state) => [state.recarga].join(),
            async () => {
                // this.showItemsNumber();
                // this.refreshButton();
                
                //console.log(this.textos)
                
                if (!this.data.tipo) {
                    this.data.tipo = ''
                }

                this.item = this.data

                this.user = JSON.parse(localStorage.getItem("user")) || false; //this.$store.state.user!=null ? true : false;
                if (this.theme) {
                    this.className = this.theme;
                }
            }
        );
        console.log('SE VOLVIO A RENDERIZAR')
        if(!this.data.tipo){
            this.data.tipo = ''
        }

        this.item = this.data

        this.user = JSON.parse(localStorage.getItem("user")) || false; //this.$store.state.user!=null ? true : false;
        if (this.theme) {
            this.className = this.theme;
        }
    },
}
</script>

<style scoped>
.width {
    width: 33.33333333333333%;
}
.w {
    width: 50% !important;
}
@media only screen and (max-width: 780px) {
    .w {
        width: 40% !important;
    }
}

#titles {
    font-family: 'Sk-Modernist', sans-serif !important;
}
</style>