<template>
  <div id="navbar" class="sticky">
    <!-- desktop -->
    <div class="bg-white ">

      <!-- div_drop_banderas en movil hoy -->
      <div
        v-if="drop_banderas"
        style="
          z-index: 333 !important;
          position: absolute !important;
          margin-top: 87px;"
        class="" id="subtitles"
      >
        <div
          v-for="item in drop_banderas_item"
          :key="item.id"
          class="btn-light p-3 text-left phone"
          @click="
            sidebar = false;
            drop_banderas = false;
            item.id == 1 ? getData(1) : item.id == 2 ? getData(2) : item.id == 3 ? getData(3) : item.id == 4 ? getData(4) : item.id == 5 ? getData(5) : item.id == 6 ? getData(6) : item.id == 7 ? getData(7) : item.id == 8 ? getData(8) : '';
          "
        >
          <img style="width: 40px" :src="item.img_bandera" alt="" />
          <span class="mx-2"
            ><b id="subtitles">{{ item.text }}</b></span
          >
        </div>
      </div>

      <!-- banderas fullscreen hoy -->
      <div id="subtitles">
        <div
          v-if="drop_banderas_fs"
          style="
            z-index: 333 !important;
            position: absolute !important;
            margin-top: 80px;right:0px"
        >
          <div
            v-for="item in drop_banderas_item"
            :key="item.id"
            class="btn-light p-3 text-left "
            @click="
              sidebar = false;
              drop_banderas = false;
              item.id == 1
                ? getData(1)
                : item.id == 2
                ? getData(2)
                : item.id == 3
                ? getData(3)
                : item.id == 4
                ? getData(4)
                : item.id == 5
                ? getData(5)
                : item.id == 6
                ? getData(6)
                : item.id == 7
                ? getData(7)
                : item.id == 8
                ? getData(8)
                : '';
            "
          >
            <img style="width: 40px" :src="item.img_bandera" alt="" />
            <span class="mx-2"
              ><b>{{ item.text }}</b></span
            >
          </div>
        </div>
      </div>

      <!-- navbar fullscreen hoy -->
      <b-navbar class="" toggleable="lg" style="justify-content: center !important; margin-top: -30px" type="light " variant="transparent">
        <b-navbar-brand class="full pointer" @click="$router.push('/')">
          <img width="100px" src="/img/logo.png" alt="" style="filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));"/>
        <img class="p-2" width="50px" src="/img/icons/Airbnbscuadrado.png" alt="" style="filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));" />
        <img class="p-2" width="50px" src="/img/icons/Bookingcuadrado.png" alt="" style="filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));"/>
        <img class="p-2" width="50px" src="/img/icons/Vrbocuadrado.png" alt="" style="filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));"/>
        <img class="p-2" width="50px" src="/img/icons/Trapadvisorcuadrado.png" alt="" style="filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));"/>
        <img class="p-2" width="50px" src="/img/icons/expediacuadrado.png" alt="" style="filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));"/>
        <img class="p-2" width="50px" src="/img/icons/agodacuadrado.png" alt="" style="filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));"/>
        </b-navbar-brand>
        <div class="phone text-center">
          <!-- button_banderas -->
          <div class="row">
            <div class="col-4 ml-0 pl-0 mt-3" style="width: 40%">
              <div class="inner phone text-center mr-2">
                <div
                  class="pb-2 "
                  @click="
                    drop_banderas = !drop_banderas;
                    sidebar = false;
                  "
                >
                  <img
                    style="width: 40px"
                    :src="
                      id_bandera == 1
                        ? '/img/e.png'
                        : id_bandera == 2
                        ? '/img/n.jpg'
                        : id_bandera == 3
                        ? '/img/f.png'
                        : id_bandera == 4
                        ? '/img/a.png'
                        : id_bandera == 5
                        ? '/img/i.png'
                        : id_bandera == 6
                        ? '/img/p.png'
                        : id_bandera == 7
                        ? '/img/r.png '
                        : id_bandera == 8
                        ? '/img/eu.png'
                        : ''
                    "
                    alt=""
                  />
                  <span class="mx-2"
                    ><b id="subtitles">{{
                      id_bandera == 1
                        ? idioma.idioma_1
                        : id_bandera == 2
                        ? idioma.idioma_2
                        : id_bandera == 3
                        ? idioma.idioma_3
                        : id_bandera == 4
                        ? idioma.idioma_4
                        : id_bandera == 5
                        ? idioma.idioma_5
                        : id_bandera == 6
                        ? idioma.idioma_6
                        : id_bandera == 7
                        ? idioma.idioma_7
                        : id_bandera == 8
                        ? idioma.idioma_8
                        : ""
                    }}</b></span
                  >
                </div>
                <b-icon @click="drop_banderas = !drop_banderas" class="mt-4" icon="caret-down-fill"></b-icon>
              </div>
            </div>
            <div class="col-4 mt-2" style="width: 40%">
              <b-navbar-brand @click="$router.push('/')"><img width="90px" class="m-0" src="/img/logo.png" alt="" style="filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));" /></b-navbar-brand>
            </div>
            <div class="col-4 pr-5 mt-2" style="padding-left: 8%; width: 40%">
              <!-- boton hamburgesa -->
              <b-button
                id="ham"
                @click="
                  sidebar = !sidebar;
                  drop_banderas = false;
                "
                class="m-2 phone btn btn-light py-2 px-3 ml-4"
              >
                <i class="fa fa-bars" id="menu-icon"></i
              ></b-button>
            </div>
          </div>
        </div>

        <!-- <<<<<<< HEAD -->
        <b-collapse id="nav-collapse" class="full mt-3" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto mb-4 navbar-links-mid" >
          <b-nav-item class="navbar-links mx-0 px-0" @click="$router.push('/')" >{{ nav_item.item_diez }}</b-nav-item>
          <b-nav-item class="navbar-links mx-0 px-0" @click="$router.push('/#Nuestros_servicio')">{{ nav_item.item_siete }}</b-nav-item>
          <b-nav-item class="navbar-links mx-0 px-0" @click="$router.push('/presupuesto')">{{ nav_item.item_seis }}</b-nav-item>
          <b-nav-item class="navbar-links mx-0 px-0" @click="$router.push('/faq')">{{ nav_item.item_nueve }}</b-nav-item>
          <b-nav-item class="navbar-links mx-0 px-0" @click="$router.push(`/${ isUserLoggedComputed ? 'panel/LINETOR_Profile' : 'login_new' }`)">{{ nav_item.item_cinco }}</b-nav-item>
          <b-nav-item v-if="isUserLoggedComputed" class="navbar-links mx-0 px-0" @click="logout">Logout</b-nav-item>
            <b-nav-item 
              class="mx-0 px-0"
              @click="  
                drop_banderas_fs = !drop_banderas_fs;
                sidebar = false;
              "
            >
              <span class="" id="subtitles"
                >{{
                  id_bandera == 1
                    ? idioma.idioma_1
                    : id_bandera == 2
                    ? idioma.idioma_2
                    : id_bandera == 3
                    ? idioma.idioma_3
                    : id_bandera == 4
                    ? idioma.idioma_4
                    : id_bandera == 5
                    ? idioma.idioma_5
                    : id_bandera == 6
                    ? idioma.idioma_6
                    : id_bandera == 7
                    ? idioma.idioma_7
                    : id_bandera == 8
                    ? idioma.idioma_8
                    : ""
                }}<i :class="drop_banderas_fs == true ? 'fa-solid fa-caret-down text-right rotate float ml-1 mb-2' : 'fa-solid fa-caret-down text-right float ml-2'" ></i>
              
            </span>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>

    <!-- sidebar tablet hoy -->
    <div v-if="sidebar == true" style="margin-top: -28px !important; margin-left: 45%;" :class="'sidebar animate__animated animate__faster tablet' + (sidebar == false ? 'animate__fadeOut tablet' : 'null')" id="subtitles">
      <button @click="$router.push('/'); sidebar=false" class="btn btn-light btn-block text-left py-3 px-4 m-0">
        {{ nav_item.item_diez }}
      </button>
      <button
        @click="
          $router.push('/#Nuestros_servicio');
          sidebar = false;
        "
        class="btn btn-light btn-block text-left py-3 px-4 m-0"
      >
        {{ nav_item.item_siete }}
      </button>

      <button @click="$router.push('/presupuesto'); sidebar=false" class="btn btn-light btn-block text-left py-3 px-4 m-0">
        {{ nav_item.item_seis }}
      </button>
      <!-- "ELIMINAR TARIFAS"<button @click="$router.push('/tarifas'); sidebar = false" class="btn btn-light btn-block text-left py-3 px-4 m-0">
         {{nav_item.tarifa_title}}
        </button> -->

      <button
        @click="
          $router.push('/faq');
          sidebar = false;
        "
        class="btn btn-light btn-block text-left py-3 px-4 m-0"
      >
        {{ nav_item.item_nueve }}
      </button>
      <button
        @click="
          $router.push('/login_new');
          sidebar = false;
        "
        class="btn btn-light btn-block text-left py-3 px-4 m-0"
      >
        {{ nav_item.item_cinco }}
      </button>
      <button
         v-if="isUserLoggedComputed" @click="logout"
          class="btn btn-light btn-block text-left py-3 px-4 m-0"
        >
          Logout
        </button>
      
    </div>

  </div>
</template>

<script>
export default {
  components: {},
  props: ["position"],
  data() {
    return {
      drop_banderas_item: [
        { id: "1", img_bandera: "/img/e.png", text: "Spanish" },
        { id: "2", img_bandera: "/img/n.jpg", text: "Nederland" },
        { id: "3", img_bandera: "/img/f.png", text: "French" },
        { id: "4", img_bandera: "/img/a.png", text: "German" },
        { id: "5", img_bandera: "/img/i.png", text: "Italian" },
        { id: "6", img_bandera: "/img/p.png", text: "Portuguese" },
        { id: "7", img_bandera: "/img/r.png", text: "Russian" },
        { id: "8", img_bandera: "/img/eu.png", text: "English" },
      ],
      idioma: {
        idioma_1: "ESP",
        idioma_2: "NED",
        idioma_3: "FRE",
        idioma_4: "GER",
        idioma_5: "ITA",
        idioma_6: "POR",
        idioma_7: "RUS",
        idioma_8: "ENG",
      },
      id_bandera: "1",
      sidebar: false,
      drop_banderas: false,
      drop_banderas_fs: false,
      nav_item: {
        // item_uno: "",
        // item_dos:'Chekin / Chekout',
        // item_tres:'Reparación',
        // item_cuatro:'Intervención',
        item_cinco: '',
        item_seis: '',
        item_siete: '',
        item_ocho: '',
        item_nueve: '',
        item_diez: '',
        
        //PRESUPUESTOS
        //NUESTROS SERVICIOS
      },
      texto: null,
      clienteRuta: ''
    };
  },
  async mounted() {
    // Obtener el idioma inicial desde la API externa
    await this.obtenerPais();
    this.emitirEventoIdioma(this.id_bandera);

    //console.log('idi', idi)
    //console.log("mounted navbar");
    this.$store.watch(
      (state) => [state.cerrarModal].join(),
      async () => {
        this.funCerrarmodal();
        this.texto = JSON.parse(localStorage.getItem("text"))
        console.log('AQUI SE ACTUALIZARA EL CAMPO')
        await this.actualizaCampos(this.texto);
        console.log(this.texto)
      }
    );

    //await this.getData()
    try {
      this.texto = JSON.parse(localStorage.getItem("text")) || {};
      //console.log("textp..--->", this.texto);
      this.id_bandera = this.texto.id;
      //1- verificar si hay datos en localstorage
      if (this.texto.id) {
        console.log('navbar . tiene id')
        //console.log("existe textocargado --->  ", this.texto);
        this.id_bandera = this.texto.id;
        //2- si hay datos, cargarlos y enviar emit a home

        await this.obtenerPais();
        await this.getData(this.id_bandera);

        await this.obtenerWhatsapp(this.texto.id);
        // await this.actualizaCampos(this.texto);
      } else {
        console.log('navbar - no tiene id')
        //3- no hay datos, cargar dependiendo del lugar
        //console.log("sin texto 1");
        //this.id_bandera = 1
        await this.obtenerPais();
        await this.getData(this.id_bandera);
        
        await this.obtenerWhatsapp(this.id_bandera);
      }
    } catch (error) {
      console.log("error ", error);
      //3- no hay datos, cargar dependiendo del lugar
      //console.log("sin texto 2");
      // console.log('no existe texto cargado, asignando 1')
      await this.obtenerPais();
      //this.id_bandera = 1
      await this.obtenerWhatsapp(this.id_bandera);
      await this.getData(this.id_bandera);

      // await this.getDataCategorias(this.id_bandera);
    }
  },
  methods: {
    async secondFunctionLang() {
      try {
        let pais = await fetch("https://api.ipgeolocation.io/ipgeo?apiKey=3ed3b224a1d146eeb2b3e3e50302d9b1")
          .then(function(response) {
            return response.json();
          })
          .then(function(payload) {
            // console.log('res secondFunctionLang  ', payload)
            // console.log("**********************>", payload);
            // console.log(payload.country_name);
            return payload.country_name;
          });
          return pais
      }
      catch(error){
        return 'España'
      }
    },
    async obtenerWhatsapp(id) {
      //console.log('--------- obtenerwhatsapp', id)
      let lang = "";
      if (id == 1) {
        lang = "linetornet";
      } else {
        if (id == 2) {
          lang = "linetorus";
        } else {
          if (id == 3) {
            lang = "linetorfr";
          } else {
            if (id == 8) {
              lang = "linetorus";
            } else {
              lang = "linetornet";
            }
          }
        }
      }

      let reqWhatsapp = await this.$store.dispatch("get", {
        path: "numeroWhatsapp/" + lang,
      });

      localStorage.setItem("numerowhatsapp", await reqWhatsapp);
      this.$store.commit("actualizanumeroWhatapp");

      //console.log("numero what ", reqWhatsapp);
    },
    funCerrarmodal() {
      if (this.$store.state.cerrarModal == true) {
        this.$bvModal.hide("my-modal");
        this.$store.commit("actualizaModal", false);
      }
    },
    shoModalPres() {
      this.$bvModal.show("my-modal");
      this.$store.commit("actualizaModal", false);
    },
    async obtenerPais() {
      let options = {
        method: "Get",
        Headers: { "Content-type": "text/html" },
      };
      let pais;
      try {
        pais = await fetch("https://api.ipregistry.co/?key=tryout")
          .then(function(response) {
            return response.json();
          })
          .then(function(payload) {
            // console.log("**********************>", payload);
            // console.log(payload.location.country.name);
            return payload.location.country.name;
          });
      } catch (error) {
        try {
          // console.log("error en eobtenerPais --->", error);
          // console.log('ejecutando segunda función getpais')
          pais = await this.secondFunctionLang();
        } catch (error) {
          pais = "España";
        }
      }

      let id;
      if (pais == "Nicaragua" || pais == "España") {
        this.id_bandera = 1;
      } else {
        if (pais == "Netherlands") {
          this.id_bandera = 2;
        } else {
          if (pais == "France") {
            this.id_bandera = 3;
          } else {
            if (pais == "Germany") {
              this.id_bandera = 4;
            } else {
              if (pais == "Italy") {
                this.id_bandera = 5;
              } else {
                if (pais == "Portugal") {
                  this.id_bandera = 6;
                } else {
                  if(pais=="United States"|| pais=="Canada" || pais == "United Kingdom"){
                    this.id_bandera = 8
                  }
                  else{
                  this.id_bandera = 1;
                  }
                }
              }
            }
          }
        }
      }
      this.emitirEventoIdioma(this.id_bandera);
    },
    async actualizaCampos(data) {
      this.$isLoading(true)
      console.log('SE RENDERIZO EL ACTUALIZACAMPOS')
      console.log(data)
      this.nav_item.item_cinco = data.navbar_titulo5;
      this.nav_item.item_seis = data.navbar_titulo6;
      this.nav_item.item_siete = data.navbar_titulo7;
      this.nav_item.item_ocho = data.navbar_titulo8;
      this.nav_item.item_nueve = data.navbar_titulo9;
      this.nav_item.item_diez = data.navbar_titulo10;
      this.nav_item.tarifa_title = data.precios_titulo
      this.$isLoading(false)
    },
    async getData(id) {

      //console.log("getdata.... ", id);
      this.id_bandera = id;
      try {
        this.$isLoading(true);
        let req = await this.$store.dispatch("get", {
          path: "linetor_textos/" + id,
        });
        // 1 español
        // 2 holandes
        // 3 frances
        // 8 ingles

        this.$store.commit("refreshRecarga");
        //console.log('res', req)
        if (req.id) {
          //console.log("ok");
          localStorage.setItem("text", JSON.stringify(req));
          localStorage.setItem("selected_language", this.idioma[`idioma_${id}`]); // Store selected language
          this.getDataCategoriasFaqs(id);
          await this.actualizaCampos(req);
          await this.obtenerWhatsapp(id);
          this.$store.commit("refreshRecarga");

          window.dispatchEvent(new CustomEvent('localStorageChanged', {
            detail: {
              storage: localStorage.getItem('text')
            }
          }));

          // Emitir evento personalizado para actualizar el chat de Tawk.to
          this.emitirEventoIdioma(id);

          //this.id_bandera = req.id
          //location.reload()
        }
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        console.log("err", error);
      }
    },
    emitirEventoIdioma(id) {
      window.dispatchEvent(new CustomEvent('languageChanged', {
        detail: {
          language: this.idioma[`idioma_${id}`]
        }
      }));
    },
    async getDataCategoriasFaqs(id) {
      this.$isLoading(true)
      id = id == 1 ? "espaniol" : id == 2 ? "holandes" : id == 3 ? "frances" : id == 4 ? "aleman" : id == 5 ? "italiano" : id == 6 ? "portugues" : id == 7 ? "ruso" : id == 8 ? "ingles" : "";
      try {
        // console.log("getDataCategoriasFaq-->  ");
        let req = await this.$store.dispatch("get", {
          path: "obtenerTextosLinetorCategoriasFaq/" + id,
        });
        // 1 español
        // 2 holandes
        // 3 frances
        // 8 ingles
        // console.log("req faqs ---------------------> ", req);

        // this.$store.commit("refreshRecarga", 1);
        localStorage.setItem("textfaqs", JSON.stringify(req));
        window.dispatchEvent(new CustomEvent('localStorageChanged', {
          detail: {
            storage: localStorage.getItem('text')
          }
        }));
        this.$store.commit("recargafaqs",1);
        this.$store.commit("refreshRecarga");

        if (req.id) {
          //console.log("ok");
          // await this.actualizaCampos(req);
          // await this.obtenerWhatsapp(id)
          //this.id_bandera = req.id
          //location.reload()
        }
      this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        console.log("err", error);
      }
    },
    goto(item) {
      item;
    },
    logout(){
      this.$store.commit("logout")
      this.$router.push({name:"Home"})

    }
  },
  computed : {
    isUserLoggedComputed(){
      // console.log(this.$store.state.user)
      return this.$store.state.user !== null ? true : false
    }
  },
  beforeMount(){
    if(JSON.parse(localStorage.getItem("user")) ){
      // console.log(JSON.parse(localStorage.getItem("user")))
      this.$store.commit("setUser", JSON.parse(localStorage.getItem("user")))
    }
  }
};
</script>

<style scoped>
.tablet {
  display: none;
}
.float {
  float: right;
}
.rotate {
  transform: rotate(3.142rad);
  animation: rotation 2s linear;
}
.animate__animated.animate__fadeOut {
  --animate-faster: 10000ms !important;
  display: none !important;
}
.phone {
  display: none;
}
.full {
  display: flex;
}
.full_2 {
  display: block !important;
}
.sidebar {
  background-color: white;
  height: 1000%;
  z-index: 333 !important;
  position: absolute !important;
  top: 118px !important;
  width: 55%;
}
.sticky {
  position: sticky !important;
  top: 0 !important;
}
#navbar {
  box-shadow: 3px 3px 3px #888888;
}
.nav-link {
  font-size: 1rem;
  display: block;
  padding-top: 17px !important;
  padding-bottom: 17px !important;
  padding-left: 14px !important;
  padding-right: 14px !important;
  cursor: pointer;
  background-color: rgb(255, 255, 255) !important;
  color: rgba(39, 39, 39, 0.76) !important;
  font-family: 'Sk-Modernist', sans-serif !important;

  transition: 0.5s ease;
  margin-bottom: -19px !important;
  line-height: 95%;
}
.nav-link:hover {
  color: black;
}
.bg-navbar {
  background-color: #12045e !important;
}
.bg-yellow {
  background-color: yellow;
  color: black;
}
.dropdown-menu {
  background-color: transparent;
}
.logo {
  width: 70%;
  height: 80%;
}
@media only screen and (max-width: 1058px) {
  .logo {
    width: 50%;
    height: 60%;
  }
  .phone {
    display: flex !important;
  }
  .full {
    display: none !important;
  }
  .full_2 {
    display: none !important;
  }

  .navbar-brand.collapse {
    display: none !important;
  }
  .navbar-brand.in {
    display: block !important;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .collapse.in {
    display: block !important;
  }
}
@media only screen and (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
    height: 80px;
  }
}
@media only screen and (min-width: 820px) {
  .sidebar {
    display: block;
    background-color: white;
    height: 1000%;
    z-index: 333 !important;
    right: 0;
    top: 118px !important;
    width: 30%;
  }
}

/* Fonts Styles */
#titles {
  font-family: 'Merriweather', serif !important;
}

#subtitles{
  font-family: 'Sk-Modernist', sans-serif !important;
}
/* Fonts Styles */

/* Estilo para la barra de carga debajo de cada nav-item y centrado */
.navbar-links {
  position: relative;
  display: inline-block; 
  vertical-align: middle; 
}

.navbar-links::after {
  content: '';
  position: absolute;
  width: 0; 
  height: 3px; 
  bottom: 0; 
  left: 0;
  background-color: #007bff; 
  transition: width 0.25s ease-out; 
}

.navbar-links:hover::after {
  width: 100%; 
}

.navbar-links-mid{
  transform: translateY(-15%);
}
/* Estilo para la barra de carga debajo de cada nav-item */

#menu-icon {
  font-size: 1.5rem !important;
}


</style>
