<template>
  <div class="container-fluid mx-0 mx-md-0 px-0">
      <div class="bg-img-property">
        <p class="title text-white div-plan" data-aos="fade-up" id="titles">
          <!-- {{textos.plan_precio_seccion_1_titulo}} -->
          {{ textos.titulo_prop_serv_seccion_1 }}
        </p>
      </div>
     <div>
      <div class="mb-2 py-5 my-2 div-3 container px-3">
        <div>
          <h2>{{ textos.subtitulo_prop_serv_seccion_1 }}</h2>
        </div>
        <div class="mt-4 div-3 px-md-0 px-md-5">
          <span>{{ textos.descripcion_prop_serv_seccion_1 }}</span>
        </div>
      </div>
      <div class="bg-img-servicios ">
        <p class="title text-white div-plan" data-aos="fade-up">
          <!-- {{textos.plan_precio_seccion_1_titulo}} -->
          {{ textos.titulo_prop_serv_seccion_2 }}
        </p>
      </div>
      <div class=" pt-5 mt-2 div-3 container px-3">
        
        <div class="">
          <span>{{ textos.descripcion_prop_serv_seccion_2 }}</span>
        </div>
      </div>
      <div id="Limpieza" class="pb-5  px-md-5 px-3  mx-md-5 ">
        <div class="row px-3">
          <card v-for="it1 in services_card_row_1" :key="it1.id" :data="it1" />
        </div>
        <div class="row px-3">
          <card v-for="it1 in services_card_row_2" :key="it1.id" :data="it1" />
        </div>
        <div class="row px-3">
          <card v-for="it1 in services_card_row_3" :key="it1.id" :data="it1" />
        </div>
      </div>
     
      <div class="bg-img-plam ">
        <p class="title text-white div-plan" data-aos="fade-up">
          <!-- {{textos.plan_precio_seccion_1_titulo}} -->
          {{ textos.titulo_prop_serv_seccion_3 }}
        </p>
      </div>
      <div id="Limpieza" class="pb-5 mt-3 px-md-5  mx-md-5 px-md-5 px-3">
        <div class="row px-3">
          <card v-for="it1 in plan_precio_card_seccion2" :key="it1.id" :data="it1" />
        </div>
        <div class="row px-3">
          <card v-for="it1 in plan_precio_card_seccion3" :key="it1.id" :data="it1" />
        </div>
        <div class="row px-3">
          <card v-for="it1 in plan_precio_card_seccion4" :key="it1.id" :data="it1" />
        </div>
      </div>
      <div class="mx-md-5 px-md-5 px-4 mb-5">
        <P>{{textos.plan_precio_seccion_5_text}}  <span class="text-primary pointer"><b>{{textos.plan_precio_seccion_5_text_link}}</b></span></P>
      </div>
     </div>
     
  </div>
</template>

<script>

import card from "../components/card.vue";
export default {
  components: { card  },
  data(){
      return{
       
        
        textos:{},
        plan_precio_card_seccion2:[
        
        ],
        plan_precio_card_seccion3:[
        
        ],
        plan_precio_card_seccion4:[
        
        ],
        services_card_row_1:[
        
        ],
        services_card_row_2:[
        
        ],
        services_card_row_3:[
        
        ],
        
      }
    },
    async mounted(){
        this.$store.watch(
          (state) => [state.recarga].join(),
          async () => {
            // this.showItemsNumber();
            // this.refreshButton();
            this.textos = JSON.parse(localStorage.getItem('text'))
            await this.llenarCampos()
          }
        );
        
        this.textos = JSON.parse(localStorage.getItem('text'))
        console.log('textos en vista planes -->',this.textos)

        await this.llenarCampos()
      },
      methods:{
        
        async llenarCampos(){
          this.plan_precio_card_seccion2= []
          this.plan_precio_card_seccion3= []
          this.plan_precio_card_seccion4= []

          this.services_card_row_1= []
          this.services_card_row_2= []
          // this.services_card_row_3= []

          let plan_precio_seccion_2_card1 = {
            id:1,
            titulo: this.textos.plan_precio_seccion_2_card1_titulo,
            descripcion: this.textos.plan_precio_seccion_2_card1_descripcion,
            img: this.textos.plan_precio_seccion_2_card1_img,
            tipo: 'plan_precio',
            precio: this.textos.plan_precio_seccion_2_card1_precio
          }
          let plan_precio_seccion_2_card2 = {
            id:1,
            titulo: this.textos.plan_precio_seccion_2_card2_titulo,
            descripcion: this.textos.plan_precio_seccion_2_card2_descripcion,
            img: this.textos.plan_precio_seccion_2_card2_img,
            tipo: 'plan_precio',
            precio: this.textos.plan_precio_seccion_2_card2_precio
          }
          let plan_precio_seccion_2_card3 = {
            id:1,
            titulo: this.textos.plan_precio_seccion_2_card3_titulo,
            descripcion: this.textos.plan_precio_seccion_2_card3_descripcion,
            img: this.textos.plan_precio_seccion_2_card3_img,
            tipo: 'plan_precio',
            precio: this.textos.plan_precio_seccion_2_card3_precio
          }
          let plan_precio_seccion_3_card1 = {
            id:1,
            titulo: this.textos.plan_precio_seccion_3_card1_titulo,
            descripcion: this.textos.plan_precio_seccion_3_card1_descripcion,
            img: this.textos.plan_precio_seccion_3_card1_img,
            tipo: 'plan_precio',
            precio: this.textos.plan_precio_seccion_3_card1_precio
          }
          let plan_precio_seccion_3_card2 = {
            id:1,
            titulo: this.textos.plan_precio_seccion_3_card2_titulo,
            descripcion: this.textos.plan_precio_seccion_3_card2_descripcion,
            img: this.textos.plan_precio_seccion_3_card2_img,
            tipo: 'plan_precio',
            precio: this.textos.plan_precio_seccion_3_card2_precio
          }
          let plan_precio_seccion_3_card3 = {
            id:1,
            titulo: this.textos.plan_precio_seccion_3_card3_titulo,
            descripcion: this.textos.plan_precio_seccion_3_card3_descripcion,
            img: this.textos.plan_precio_seccion_3_card3_img,
            tipo: 'plan_precio',
            precio: this.textos.plan_precio_seccion_3_card3_precio
          }
          let plan_precio_seccion_4_card1 = {
            id:1,
            titulo: this.textos.plan_precio_seccion_4_card1_titulo,
            descripcion: this.textos.plan_precio_seccion_4_card1_descripcion,
            img: this.textos.plan_precio_seccion_4_card1_img,
            tipo: 'plan_precio',
            precio: this.textos.plan_precio_seccion_4_card1_precio
          }
          let plan_precio_seccion_4_card2 = {
            id:1,
            titulo: this.textos.plan_precio_seccion_4_card2_titulo,
            descripcion: this.textos.plan_precio_seccion_4_card2_descripcion,
            img: this.textos.plan_precio_seccion_4_card2_img,
            tipo: 'plan_precio',
            precio: this.textos.plan_precio_seccion_4_card2_precio
          }
          let plan_precio_seccion_4_card3 = {
            id:1,
            titulo: this.textos.plan_precio_seccion_4_card3_titulo,
            descripcion: this.textos.plan_precio_seccion_4_card3_descripcion,
            img: this.textos.plan_precio_seccion_4_card3_img,
            tipo: 'plan_precio',
            precio: this.textos.plan_precio_seccion_4_card3_precio
          }


          let services_card_row_1_card1 = {
            id:1,
            titulo: this.textos.services_card_row_1_card_1_titulo,
            // titulo: this.textos.plan_precio_seccion_2_card1_titulo,
            descripcion: this.textos.services_card_row_1_card_1_descripcion,
            img: this.textos.services_card_row_1_card_1_img,
            // tipo: 'plan_precio',
            
          }
          let services_card_row_1_card2 = {
            id:1,
            titulo: this.textos.services_card_row_1_card_2_titulo,
            // titulo: this.textos.plan_precio_seccion_2_card2_titulo,
            descripcion: this.textos.services_card_row_1_card_2_descripcion,
            img: this.textos.services_card_row_1_card_2_img,
            // tipo: 'plan_precio',
           
          }
          let services_card_row_1_card3 = {
            id:1,
            titulo: this.textos.services_card_row_1_card_3_titulo,
            // titulo: this.textos.plan_precio_seccion_2_card3_titulo,
            descripcion: this.textos.services_card_row_1_card_3_descripcion,
            img: this.textos.services_card_row_1_card_3_img,
            // tipo: 'plan_precio',
            
          }


          let services_card_row_2_card1 = {
            id:1,
            titulo: this.textos.services_card_row_2_card_1_titulo,
            // titulo: this.textos.plan_precio_seccion_3_card1_titulo,
            descripcion: this.textos.services_card_row_2_card_1_descripcion,
            img: this.textos.services_card_row_2_card_1_img,
            // tipo: 'plan_precio',
           
          }
          let services_card_row_2_card2 = {
            id:1,
            titulo: this.textos.services_card_row_2_card_2_titulo,
            // titulo: this.textos.plan_precio_seccion_3_card2_titulo,
            descripcion: this.textos.services_card_row_2_card_2_descripcion,
            img: this.textos.services_card_row_2_card_2_img,
            // tipo: 'plan_precio',
            
          }
          let services_card_row_2_card3 = {
            id:1,
            titulo: this.textos.services_card_row_2_card_3_titulo,
            // titulo: this.textos.plan_precio_seccion_3_card3_titulo,
            descripcion: this.textos.services_card_row_2_card_3_descripcion,
            img: this.textos.services_card_row_2_card_3_img,
            // tipo: 'plan_precio',
            
          }
          // let services_card_row_3_card1 = {
          //   id:1,
          //   titulo: this.textos.services_card_row_3_card_1_titulo,
          //   // titulo: this.textos.plan_precio_seccion_3_card1_titulo,
          //   descripcion: this.textos.services_card_row_3_card_1_descripcion,
          //   img: this.textos.services_card_row_3_card_1_img,
          //   // tipo: 'plan_precio',
          //   precio: this.textos.services_card_row_3_card_1_precio
          // }
          // let services_card_row_3_card2 = {
          //   id:1,
          //   titulo: this.textos.services_card_row_3_card_2_titulo,
          //   // titulo: this.textos.plan_precio_seccion_3_card2_titulo,
          //   descripcion: this.textos.services_card_row_3_card_2_descripcion,
          //   img: this.textos.services_card_row_3_card_2_img,
          //   // tipo: 'plan_precio',
          //   precio: this.textos.services_card_row_3_card_2_precio
          // }
          // let services_card_row_3_card3 = {
          //   id:1,
          //   titulo: this.textos.services_card_row_3_card_3_titulo,
          //   // titulo: this.textos.plan_precio_seccion_3_card3_titulo,
          //   descripcion: this.textos.services_card_row_3_card_3_descripcion,
          //   img: this.textos.services_card_row_3_card_3_img,
          //   // tipo: 'plan_precio',
          //   precio: this.textos.services_card_row_3_card_3_precio
          // }
          
        
          this.plan_precio_card_seccion2.push(plan_precio_seccion_2_card1)
          this.plan_precio_card_seccion2.push(plan_precio_seccion_2_card2)
          this.plan_precio_card_seccion2.push(plan_precio_seccion_2_card3)
          
          this.plan_precio_card_seccion3.push(plan_precio_seccion_3_card1)
          this.plan_precio_card_seccion3.push(plan_precio_seccion_3_card2)
          this.plan_precio_card_seccion3.push(plan_precio_seccion_3_card3)
          
          this.plan_precio_card_seccion4.push(plan_precio_seccion_4_card1)
          this.plan_precio_card_seccion4.push(plan_precio_seccion_4_card2)
          this.plan_precio_card_seccion4.push(plan_precio_seccion_4_card3)                       
          
          
          this.services_card_row_1.push(services_card_row_1_card1)
          this.services_card_row_1.push(services_card_row_1_card2)
          this.services_card_row_1.push(services_card_row_1_card3)

          this.services_card_row_2.push(services_card_row_2_card1)
          this.services_card_row_2.push(services_card_row_2_card2)
          this.services_card_row_2.push(services_card_row_2_card3)
          
          // this.services_card_row_3.push(services_card_row_3_card1)
          // this.services_card_row_3.push(services_card_row_3_card2)
          // this.services_card_row_3.push(services_card_row_3_card3)
          
          // console.log('cards seccion 3', this.cards_seccion_5)


        }
      }
}
</script>

<style>
.div-plan {
  padding-top: 190px;
  width: 66% !important;
  text-align: center !important;
  margin-left: 17%;
}
.bg-img-plam{
  /* The image used */
  background-image: url("/img/8.jpg");

  /* Set a specific height */
  height: 460px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-img-property{
  /* The image used */
  background-image: url("/img/property.jpg");

  /* Set a specific height */
  height: 460px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-img-servicios{
  background-image: url("/img/serv_integral.jpg");

  /* Set a specific height */
  height: 460px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Fonts Styles */
#titles {
  font-family: 'Merriweather', serif;
}

#subtitles{
  font-family: 'Sk-Modernist', sans-serif !important;
}
/* Fonts Styles */
</style>