<template>
  <div>
    <div class="container-fluid login-container">
      <div class="row">
        
        <div class="col-md-4 py-md-5 px-md-5 pr-md-0 pr-4 mt-md-5">
          <b-form @submit.prevent="login()">
            <div class="px-3 py-5 text-center">
              <div class="mb-2">
                <div class="title" data-aos="fade-down">
                  <h1 class="text-center" id="titles" >{{text_login.login_title}}</h1>
                </div>
              </div>
              <div class="row mt-5" id="titles">
                <div class="col-md-12 p-0 pl-3" data-aos="fade-right">
                  <input
                    v-model="row.email"
                    required
                    type="text"
                    class="form-control"
                    id="exampleInputPassword1"
                    :placeholder="text_login.login_number_user"
                  />
                </div>
              </div>
              <div class="row mt-3" id="titles">
                <div
                  class="col-md-12 p-0 pl-3"
                  data-aos="fade-right"
                  data-aos-duration="500"
                >
                  <input
                    v-model="row.clave"
                    required
                    type="password"
                    class="form-control"
                    id="exampleInputPassword1"
                    :placeholder="text_login.login_pass"
                  />
                </div>
                <button
                style="width: 100%; filter: drop-shadow(rgba(0, 0, 0, 0.7) 1px 1px 4px); border-radius: 50px; "
                type="submit"
                data-aos="fade-right"
                data-aos-duration="800"
                class="btn btn-primary mt-3 btn-bl  ock ml-3" 
                id="titles"
                
              >
                <p class="buttons-txts">{{text_login.login_button}}</p> 
              </button>
              </div>
              
              <div class="mt-3" id="titles">
                <p data-aos="fade-up" data-aos-duration="800">
                  {{text_login.login_pregunta}}
                  <a @click="$router.push('/registro')" class="text-dark"
                    >{{text_login.login_register}}</a
                  >
                </p>
              </div>
            </div>
          </b-form>
        </div>
        <div class="col-md p-0 full bg-img">
          <h2 style="margin-top:250px;width:80%;margin-left:10%;" class="text-light custome-styles" id="titles">{{ text_login.seccion_1_texto_banner }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data: () => ({
    registro: false,
    email: "",
    row: { email: "", clave: "" },
    textos:{},
    textos_banner: {},
    text_login: {
     
      // login_title: 'Login',
      // login_email: 'E-mail',
      // login_pass: 'contraseña',
      // login_button: 'Iniciar sesón',
      // login_pregunta: '¿Aún no tienes cuenta?',
      // login_register: 'Regístrate aquí',
      // login_olvide_contraseña: '4'
    },
    origen: "",
    
  }),
  async mounted() {
    
    this.origen = this.$route.params.origen;
    this.$store.watch(
        (state) => [state.recarga].join(),
        async () => {
          // this.showItemsNumber();
          // this.refreshButton();
          this.textos = JSON.parse(localStorage.getItem('text'))
          await this.llenarCampos()
          //console.log(this.textos)
        }
      );
      this.textos = JSON.parse(localStorage.getItem('text'))
      await this.llenarCampos()
    // if(this.$store.state.user!=null){
    //   this.$router.push({name: 'Checkout'});
    // }
  },
  async beforeMount(){
  
    let isUser = await this.isUserLoggeed()
    this.$isLoading(false)
    if (isUser) window.location = '#/panel/LINETOR_Profile'
  },
  methods: {
    async recoverPassword() {
      if (this.email == "") {
        // this.$swal({
        //   icon: "error",
        //   title: "Oopss!",
        //   text: "Debes ingresar tu correo electrónico.",
        // });
        return;
      }
      const data = {
        mail: this.email,
      };
      const recoverPass = await this.$store.dispatch("saveNew", {
        table: "recoverPassword",
        data,
      });
      // this.$bvModal.hide("modal-recoverPassword");
      this.email = "";
      // this.$swal({
      //   icon: "success",
      //   title: "Muy bien!",
      //   text: "En breve te enviaremos tu nueva contraseña!",
      // });
    },
    async llenarCampos(){
      
      this.text_login={
        login_title: this.textos.login_title,
        login_email: this.textos.login_email,
        login_pass: this.textos.login_pass,
        login_button: this.textos.login_button,
        login_number_user: this.textos.login_input_numero_usuario,
        login_olvide_contraseña: this.textos.login_olvide_contraseña,    
        seccion_1_texto_banner: this.textos.seccion_1_texto_banner,           
      }
    },
    async login() {
      // let user={
      //   status: 1,
      //   address:'Calle la for Nº25',
      //   phone: '505-55698785',
      //   nit: '12321-2121123',
      //   customerName:'José Pérez',
      //   rol: 1
      // }
      try {
        this.$isLoading(true);
        let req = await this.$store.dispatch("login", { data: this.row });
        this.$isLoading(false);
       
        if (req) {
          this.$store.commit("setUser", req);

              
          this.$router.push('/panel/LINETOR_Profile')
          if (this.origen == "naodeals") {
            // this.$router.push("/naoDeals");
            return;
          }
          if (this.origen == "naotools") {
            // this.$router.push("/admin/NAO_Tools");
            return;
          }
          if (req.data.rol == 1) {
            // this.$router.push("/admin/NAO_Quote");
          }
          if (req.data.rol == 99) {
            if (req.data.estado == 1) {
              // this.$router.push("/admin/NAO_Profile");
            } else {
              // this.$swal({
              //   icon: "error",
              //   title: "Oopss!",
              //   text: "Error, usuario pendiente de validación.",
              // });
            }
          }
        } else {
          //console.log('else')
        }
      } catch (error) {
        // this.$isLoading(false);
        console.log(error);
      }
    },
    async isUserLoggeed() {
      let user = await JSON.parse(localStorage.getItem("user")) 
      
      if(user !== null) return true
      return false

    }
  },
};
</script>


<style>
.bg-img{
  background-image:  url('/img/1.jpg');

    /* Set a specific height */
    height: 600px; 

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.inner {
  max-width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.scroll-container {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;

  scroll-snap-type: mandatory;
  scroll-behavior: smooth;

  word-wrap: break-word !important;
  margin-bottom: 50px;
}

.scroll-container .scroll-section {
  display: inline-block;
  vertical-align: middle;

  /* white-space: nowrap; */
}
/*Slider X (different sized images)*/

.scroll-container.diff-size {
  scroll-snap-destination: 50% 0%;
  padding: 20px 0px;
  margin: 1%;
}

.scroll-container.diff-size .scroll-section {
  width: auto;
  height: 100%;
  margin-right: 15px;
  scroll-snap-coordinate: 50% 0%;
}

.scroll-container.diff-size .scroll-section:first-child {
  scroll-snap-coordinate: 0% 0%, 50% 0%;
  margin-left: 15px;
}

.scroll-container.diff-size .scroll-section:last-child {
  scroll-snap-coordinate: 50% 0%, 100% 0%;
}
.card-div {
  height: auto !important;
  width: 264px !important;
}
.phone{
    display: none;
  }
  .full{
    display: flex;
}
.searchdiv {
  /* background-color:#fd306e !important; */
  /* background-color: black !important; */
  /* padding: 30px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px; */
}
.buttonsearch {
  background-color: #fd306e !important;
  color: white;
}
.inputSearch {
  border-color: #fd306e !important;
}

.carousel-item img {
  /* height:100vh!important ; */
}
@media only screen and (max-width: 780px) {
  
  .phone{
    display: flex;
  }
  .full{
    display: none;
}
}

/* Fonts Styles */
#titles {
  font-family: 'Merriweather', serif !important;
}

#subtitles{
  font-family: 'Sk-Modernist', sans-serif !important;
}
/* Fonts Styles */

.buttons-txts{
    color: #ffffff !important;
    transform: translateY(8px);
}

/* Sombreado a titulos Styles */
.custome-styles{
  text-shadow: 6px 3px 8px rgba(0, 0, 0, 0.8);
}

@media(max-width: 580px) {
  .login-container {
    padding: 80px;
  }

}

</style>